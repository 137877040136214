@import "~@/erp/styles/variables/variables.scss";





























.fold-unfold {
  color: $base-color-grey;
  cursor: pointer;
  font-size: 18px;
}
.collapse-btn {
  position: absolute;
  right: 10px;
  top: 20px;
}

@import "~@/erp/styles/variables/variables.scss";



























































































































.message-dot {
  display: inline-block;
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 7px;
  position: absolute;
  right: -2px;
}

.notice-title {
  display: flex;
  padding: 0;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.notice-list {
  margin-left: -12px;
  margin-right: -12px;
  height: 400px;
  position: relative;

  ul {
    padding: 0;
    margin: 0;
    height: 400px;
    overflow: auto;

    li {
      position: relative;
      padding: 10px 0 10px 0;
      border-bottom: 1px solid #eee;

      div.wrapper {
        padding-left: 10px;
        padding-right: 10px;

        span.yq {
          height: 8px;
          width: 8px;
          border-radius: 8px;
          vertical-align: middle;
          background-color: red;
          display: inline-block;
          margin-right: 3px;
        }

        span.title {
          font-weight: 400;
          color: #000000;
        }
      }

      div.time {
        color: #5e5e5e;
        font-size: 13px;
        text-align: right;
        margin-right: 10px;
      }

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.line {
  height: 1px;
  background-color: #eee;
  margin: 0;
  margin-left: -12px;
  margin-right: -12px;
}

.notice-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  // border-top: 1px solid #e8eaec;

  i {
    margin-right: 3px;
  }
}

.el-icon-close {
  cursor: pointer;
}

@import "~@/erp/styles/variables/variables.scss";



































































































































































































































.erp-nav {
  position: relative;
  height: $base-nav-height;
  padding-right: $base-padding;
  padding-left: $base-padding;
  overflow: hidden;
  user-select: none;
  background: $base-color-white;
  box-shadow: $base-box-shadow;

  .left-panel {
    display: flex;
    align-items: center;
    justify-items: center;
    height: $base-nav-height;

    ::v-deep {
      .erp-breadcrumb {
        margin-left: $base-margin;
      }

      .el-tabs {
        margin-left: $base-margin;

        .el-tabs__header {
          margin: 0;
        }

        .el-tabs__item {
          >div {
            display: flex;
            align-items: center;

            i {
              margin-right: 3px;
            }
          }
        }
      }

      .el-tabs__nav-wrap::after {
        display: none;
      }
    }
  }

  .right-panel {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    height: $base-nav-height;
    padding-right: 20px;

    ::v-deep {
      [class*='ri-'] {
        margin-left: $base-margin;
        color: $base-color-grey;
        cursor: pointer;
      }

      button {
        [class*='ri-'] {
          margin-left: 0;
          color: $base-color-white;
          cursor: pointer;
        }
      }
    }
  }
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 100% 100%;
}

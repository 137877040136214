@import "~@/erp/styles/variables/variables.scss";/**
 * @description 绿荫草场主题
 */

.erp-theme-green {
  $base-menu-background: #20222a;

  $base-color-blue: #41b584;
  $base-color-green: #67c23a;
  $base-color-yellow: #e6a23c;
  $base-color-red: #f56c6c;
  @import '../default.scss';

  @mixin container {
    background: $base-menu-background !important;
  }

  @mixin container-common {
    background: $base-menu-background !important;
  }

  @mixin active {
    &:hover {
      color: $base-color-white !important;
      background-color: $base-color-blue !important;
    }

    &.is-active {
      color: $base-color-white !important;
      background-color: $base-color-blue !important;
    }
  }

  .logo-container-vertical,
  .logo-container-horizontal,
  .logo-container-comprehensive {
    @include container;
  }

  .logo-container-column {
    .logo {
      @include container;
    }
  }

  .column-bar-container.el-scrollbar {
    .el-tabs {
      .el-tabs__nav-wrap.is-left {
        @include container;
      }

      .el-tabs__nav {
        @include container;
      }

      .el-tabs__item.is-active {
        background: $base-color-blue !important;
      }
    }

    .el-menu {
      .el-menu-item.is-active,
      .el-submenu__title.is-active,
      .el-menu-item:hover,
      .el-submenu__title:hover {
        i {
          color: $base-color-blue !important;
        }

        color: $base-color-blue !important;
        background-color: $base-color-blue-light-9 !important;
      }
    }
  }

  .column-bar-container-card.el-scrollbar {
    .el-tabs {
      .el-tabs__item.is-active {
        background: transparent !important;

        .column-grid {
          background: $base-color-blue !important;
        }
      }
    }
  }

  .erp-layout-vertical,
  .erp-layout-comprehensive {
    .el-menu {
      @include container;

      .el-submenu__title {
        @include container;
      }

      .el-menu-item {
        @include container;
      }
    }

    .erp-side-bar,
    .comprehensive-bar-container {
      @include container;

      .el-menu-item {
        @include active;
      }
    }
  }

  .erp-layout-common {
    .el-menu {
      @include container-common;

      .el-submenu__title {
        @include container-common;
      }

      .el-menu-item {
        @include container-common;
      }
    }

    .erp-side-bar {
      @include container-common;

      .el-menu-item {
        @include active;
      }
    }
  }

  .erp-nav {
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: $base-color-blue !important;
    }

    .el-tabs__active-bar {
      background-color: $base-color-blue !important;
    }
  }

  #nprogress {
    .bar {
      background: $base-color-blue !important;
    }

    .peg {
      box-shadow: 0 0 10px $base-color-blue, 0 0 5px $base-color-blue !important;
    }
  }

  .step-form-container {
    .el-steps {
      .el-step__head.is-finish {
        .el-step__icon.is-text {
          color: $base-color-white !important;
          background: $base-color-blue !important;
        }

        .el-step__line {
          background: $base-color-blue !important;
        }
      }

      .el-step__head.is-process {
        color: $base-color-blue !important;
        border-color: $base-color-blue !important;

        .el-step__icon.is-text {
          color: $base-color-blue !important;
          background: mix($base-color-white, $base-color-blue, 90%) !important;
        }
      }

      .el-step__title.is-process {
        color: $base-color-blue !important;
      }

      .el-step__description.is-process {
        color: $base-color-blue !important;
      }

      .el-step__head.is-wait {
        .el-step__line {
          background: $base-color-blue !important;
        }
      }
    }
  }

  .el-alert {
    &--info.is-light {
      color: $base-color-blue !important;
      background-color: mix(
        $base-color-white,
        $base-color-blue,
        90%
      ) !important;
      border: 1px solid $base-color-blue !important;

      i {
        color: $base-color-blue !important;
      }
    }
  }
  .remix-icon-container .el-card__body::after {
    background-color: $base-color-blue !important;
  }
}

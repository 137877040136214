@import "~@/erp/styles/variables/variables.scss";











































































































.content-wrapper {
  height: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  .form-wrapper {
    flex: 1;
    overflow: auto;
  }
  .footer {
    text-align: left;
    .el-button {
      margin-right: 10px;
    }
  }
}

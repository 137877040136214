@import "~@/erp/styles/variables/variables.scss";





























































































































.erp-drawer {
  .el-drawer__header {
    margin-bottom: $base-margin;
  }

  .el-drawer__body {
    padding: 0 $base-padding/2 $base-padding/2 $base-padding/2;

    .el-divider--horizontal {
      margin: 20px 0 20px 0;
    }

    .el-form-item {
      display: flex;
      align-items: center;

      .el-form-item__label {
        flex: 1 1;
      }

      .el-form-item__content {
        flex: 0 0 auto;
      }
    }

    .el-form-item--small.el-form-item {
      .el-input__inner {
        width: 115px;
      }
    }
  }

  .el-drawer__footer {
    padding: $base-padding/2;
    border-top: 1px solid $base-border-color;
  }
}

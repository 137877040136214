@import "~@/erp/styles/variables/variables.scss";/**
 * @description 碰触纯白主题
 */

body.erp-theme-white {
  $base-menu-background: #fff;
  $base-color-blue: #1890ff;
  $base-color-blue-light: mix($base-color-white, $base-color-blue, 90%);

  @mixin container {
    color: #515a6e !important;
    background: $base-menu-background !important;
  }

  @mixin container-column {
    color: #515a6e !important;
    background: #f7faff !important;
  }

  @mixin active {
    &:hover {
      color: $base-color-blue !important;
      background-color: $base-color-blue-light !important;
    }

    &.is-active {
      color: $base-color-blue !important;
      background-color: $base-color-blue-light !important;
    }
  }

  .logo-container-common,
  .logo-container-vertical,
  .logo-container-horizontal,
  .logo-container-comprehensive {
    @include container;

    .title,
    i[class^='el-icon'] {
      @include container;
    }
  }

  .logo-container-column {
    @include container;

    .title {
      @include container;
    }

    .logo,
    i[class^='el-icon'] {
      @include container-column;
    }
  }

  .column-bar-container {
    .el-tabs {
      @include container-column;

      .el-tabs__nav-wrap.is-left {
        background: #f7faff !important;
      }

      .el-tabs__item,
      .el-tabs__nav {
        @include container-column;
      }

      .el-tabs__item.is-active {
        color: $base-color-white !important;
        background: $base-color-blue !important;
      }
    }

    .el-menu {
      .el-menu-item.is-active,
      .el-submenu__title.is-active,
      .el-menu-item:hover,
      .el-submenu__title:hover {
        i {
          color: $base-color-blue !important;
        }

        color: $base-color-blue !important;
        background-color: $base-color-blue-light !important;
      }
    }

    &-card {
      .el-tabs {
        .el-tabs__item {
          &.is-active {
            background: transparent !important;
          }
        }
      }
    }
  }

  .erp-layout-vertical,
  .erp-layout-comprehensive,
  .erp-layout-common {
    .el-menu {
      @include container;

      .el-submenu__title {
        @include container;
      }

      .el-menu-item {
        @include container;
      }
    }

    .erp-side-bar,
    .comprehensive-bar-container {
      @include container;
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;

      .el-menu-item {
        @include active;
      }
    }
  }
}

@import "~@/erp/styles/variables/variables.scss";













































































.summary-wrapper {
  border-radius: 5px;
  /* background-color: #eee; */
  margin-top: 5px;
  padding: 10px 5px;
  display: flex;
  .summary-list {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    .summary-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
      span {
        display: inline-block;
        &.summary-item--label {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }
}

@import "~@/erp/styles/variables/variables.scss";













































































.remove-all-notifaction.el-notification {
  z-index: 999999 !important;
  height: 34px;

  width: 80px;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  cursor: pointer;
  padding: 0px;
  .el-notification__content {
    margin: 0;
  }
}

@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































.audit-progress {
  .el_timeline {
    .floor {
      margin-left: -20px;
      .floor-content-row {
        margin-left: 20px;
      }
    }
  }
}
.el-tooltip {
  span {
    font-weight: normal !important;
  }
}
.text-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  font-weight: normal;
}

.el_timeline {
  width: 90%;
  margin: 0 auto;
}
.el-avatar {
  width: 35px;
  height: 35px;
}
::v-deep {
  .el_timeline {
    .el-timeline-item__wrapper {
      .el-timeline-item__timestamp.is-top {
        font-size: 14px;
        color: #000 !important;
      }
    }
  }
  .el-timeline-item__timestamp.is-top {
    padding-top: 0;
  }
}
.remark {
  text-align: justify;
  word-break: break-all;
  white-space: pre-wrap;
  max-width: 600px;
}

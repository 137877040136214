@import "~@/erp/styles/variables/variables.scss";

























































.img-list {
  .img-wrapper {
    height: 100px;
    width: 100px;
    border-radius: 6px;
    display: inline-block;
    margin: 5px;
    position: relative;
    img,
    .el-image {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
    .mask {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.6);
      text-align: center;
      border-radius: 6px;
      i {
        margin-top: 45px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
    &:hover {
      div {
        display: block;
      }
    }
  }
  .plus {
    border: 1px dashed #c0ccda;
    height: 100px;
    width: 100px;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    text-align: center;
    cursor: pointer;
    i {
      position: absolute;
      top: 40px;
      left: 40px;
      font-size: 20px;
    }
  }
}

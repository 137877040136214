@import "~@/erp/styles/variables/variables.scss";












































































































































































































.base-table {
  .page-custom {
    height: 58px;
  }

  ::v-deep {
    .el-pagination {
      margin: 0;
      padding-top: 18.5px;
      display: flex;
      justify-content: center;
    }

    .el-table {
      border-left: none;
    }

    .base-table-header {

      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .no-border {
      border-right: 1px solid transparent !important;
    }

    .el-table__fixed::before,
    .el-table__fixed-right::before {
      height: 0;
    }
  }
}

@import "~@/erp/styles/variables/variables.scss";























































































::v-deep {
  .el-submenu__icon-arrow {
    display: none;
  }
}

@import "~@/erp/styles/variables/variables.scss";























.erp-footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 22px;
  padding: 0 $base-padding 0 $base-padding;
  color: rgba(0, 0, 0, 0.45);
  background: $base-color-white;
  i {
    margin: 0 5px;
  }
}

@import "~@/erp/styles/variables/variables.scss";




























































.x-handle {
  width: 2px;
  cursor: w-resize;
  z-index: 10;
  background: #ccc;
}

@import "~@/erp/styles/variables/variables.scss";















































































































.avatar-dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  .icon-person {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 50%;
    background: #ededed;
    color: #999;
    text-align: center;
  }

  .user-name {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    height: 40px;
    margin-left: 6px;
    line-height: 40px;
    cursor: pointer;

    span.pad {
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    [class*='ri-'] {
      margin-left: 0 !important;
    }
  }

  .user-avatar {
    width: 30px;
    height: 30px;
    margin-left: 15px;
  }
}

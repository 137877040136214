@import "~@/erp/styles/variables/variables.scss";



























































































































































































































































































































































.demo-form-inline {
  box-sizing: border-box;
  padding: 0 20px;
}
.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .el-progress {
    width: 100px;
  }
}

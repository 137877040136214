@import "~@/erp/styles/variables/variables.scss";





























.title-line-ellipsis {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; // 解决英文的情况下不出现省略号
  text-align: justify; // 优化 word-wrap: break-word; 带来的留白
}

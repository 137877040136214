@import "~@/erp/styles/variables/variables.scss";










































































































































































































.dialog_tips {
  color: #f56c6c;
  margin-bottom: 20px;
}

.dialog-content {
  // height: 40vh;
  overflow: hidden;
  overflow-y: auto;

  .email-item {
    border-bottom: 1px dashed gray;

    &:nth-last-child(1) {
      border-bottom: 0;
    }
  }

  .el-form {
    width: 86%;
    margin: 0 auto;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }
}

img {
  height: 40px;
}

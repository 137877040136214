@import "~@/erp/styles/variables/variables.scss";






























































































































































































.upload-wrapper {
  position: absolute;
  top: -5000px;
}

.el-image-wrapper {
  height: 60px;
  width: 60px;
  position: relative;
  border: 1px dashed #c0ccda;
  border-radius: 5px;

  /* .el-icon-zoom-in,
  .el-icon-delete, */
  .opt-wrapper,
  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #8c939d;
  }

  .el-icon-plus {
    padding: 20px;
  }

  .opt-wrapper {
    display: inline-block;
    width: 60px;
    height: 60px;
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 999;

    i {
      margin: 22px 5px 0 5px;
    }
  }
}

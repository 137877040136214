@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































.search-form {
  .base-search_form {
    display: flex;
    justify-content: space-between;

    .search-container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-flow: row wrap;
      padding-right: 20px;
      flex-grow: 1;

      > div {
        margin-right: 10px;
      }
    }

    .more-search {
      position: absolute;
      top: 0;
      width: 60px;
      line-height: 30px;
      font-size: 14px;
      cursor: pointer;
      color: #000;
      text-align: right;

      span {
        display: block;
        height: 40px;

        i {
          color: #687c97;
          margin-left: 5px;
          font-weight: 600;
          vertical-align: middle;
        }
      }
    }

    .searchBtn {
      display: flex;

      .btngroups {
        display: inline-flex;
        align-items: flex-start;
        margin-left: 5px;

        .el-button {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .mt-2 {
    margin-top: 2px;
  }

  .tbold {
    font-weight: 500;
    font-size: 13px;
    color: #309bff;
    margin-left: 15px;
  }
}

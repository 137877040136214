@import "~@/erp/styles/variables/variables.scss";



































































.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}

.imgs-sty {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  margin: 3px 0;
}

::v-deep .wrapper .el-image__inner {
  height: 80px !important;
  width: 80px !important;
}

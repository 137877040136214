@import "~@/erp/styles/variables/variables.scss";













































.erp-detail-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  top: 0;
  bottom: 0;

  background: #fff;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  .erp-detail-content {
    flex: 1;
    overflow: auto;
  }
  .erp-detail-footer {
    margin-top: 10px;
  }
}

@import "~@/erp/styles/variables/variables.scss";



































































































































































































.el-select-dropdown__item .wrapper {
  display: flex;
  span {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tl {
  text-align: left;
  padding-right: 5px;
}
.tr {
  text-align: right;
  padding-left: 5px;
  color: #909399;
}

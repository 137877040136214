@import "~@/erp/styles/variables/variables.scss";










































































.erp_web_wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  [class*='erp-layout-'] {
    position: relative;

    ::v-deep {
      .erp-layout-header {
        box-shadow: $base-box-shadow;
      }
    }

    &.fixed {
      padding-top: $base-nav-height + $base-tabs-height;
    }

    &.fixed.no-tabs-bar {
      padding-top: $base-nav-height;
    }
  }

  ::v-deep {
    .fixed-header {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: $base-z-index - 1;
      width: 100%;
    }

    .erp-main {
      position: relative;
      width: auto;
      min-height: 100%;
      margin-left: $base-left-menu-width;

      &.is-collapse-main {
        margin-left: $base-left-menu-width-min;

        .fixed-header {
          left: $base-left-menu-width-min;
          width: calc(100% - #{$base-left-menu-width-min});
        }
      }
    }
  }

  /* 手机端开始 */
  &.mobile {
    ::v-deep {
      .erp-layout-vertical {
        .el-scrollbar.erp-side-bar.is-collapse {
          width: 0;
        }

        .erp-main {
          .fixed-header {
            left: 0;
            width: 100%;
          }

          width: 100%;
          margin-left: 0;
        }
      }

      /* 隐藏分页和页码跳转 */
      .el-pager,
      .el-pagination__jump {
        display: none;
      }
    }
  }

  /* 手机端结束 */
}

@import "~@/erp/styles/variables/variables.scss";
















































































.taskMessage {
  z-index: 999999 !important;
  width: 350px !important;

  .el-notification__title {
    font-size: 14px;
    width: 260px;
  }
}

.mark-box {
  padding-top: 24px !important;
  position: relative;

  .el-tag {
    position: absolute;
    right: 0;
    top: 0 !important;
    z-index: 2;

    &.el-tag-other {
      top: 24px !important;
      background-color: #fff7e8;
      border-color: #ffefd1;
      color: #ffae18;
    }
  }
}

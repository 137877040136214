@import "~@/erp/styles/variables/variables.scss";


























































.tip-icon {
  margin: 0 5px;
}

@import "~@/erp/styles/variables/variables.scss";


















































/********换行居左**********/
.prewrap-left {
  white-space: pre-wrap;
  text-align: left;
  word-break: break-word;
}

@import "~@/erp/styles/variables/variables.scss";
























.vertical {
  display: inline-block;
  height: 20px;
  width: 5px;
  background-color: #409eff;
  vertical-align: middle;
}
h3 {
  margin-bottom: 15px;
}

<template>
  <div class="search-form">
    <el-form
      ref="searchForm"
      :model="value"
      :rules="rules"
      class="base-search_form"
      :label-position="searchFormConfig.labelPosition"
    >
      <div ref="searchContainer" class="search-container">
        <div v-for="(item, i) in searchFormConfig.formItemList" :key="i">
          <template v-if="item.type === 'text'">
            <el-form-item
              ref="elFormItem"
              :prop="item.prop ? item.prop : ''"
              class="search-form-item"
              :label="item.label"
              :label-width="item.labelWidth || '70px'"
            >
              <el-input
                v-model="value[item.name]"
                :type="item.type"
                :placeholder="item.placeholder"
                :maxlength="item.maxlength"
                clearable
                style="width: 170px"
                @keyup.enter.native="enterSearch($event)"
              />
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'userchoose'">
            <el-form-item
              ref="elFormItem"
              :prop="item.prop ? item.prop : ''"
              class="search-form-item"
              :label="item.label"
              :label-width="item.labelWidth || '70px'"
            >
              <el-input
                v-model="value[item.name]"
                style="width: 170px"
                :type="item.type"
                :placeholder="item.placeholder"
                clearable
                @focus="item.chooseUser"
              />
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'userselect'">
            <el-form-item
              ref="elFormItem"
              :prop="item.prop ? item.prop : ''"
              class="search-form-item"
              :label="item.label"
              :label-width="item.labelWidth || '70px'"
            >
              <UserSelect
                ref="userSelect"
                :role-name="item.roleName"
                :default-user="getDefaultUser(item)"
                style="width: 170px"
                @user-choose="item.chooseUser"
              />
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'userselectWithTab'">
            <el-form-item
              ref="elFormItem"
              :prop="item.prop ? item.prop : ''"
              class="search-form-item"
              :label="item.label"
              :label-width="item.labelWidth || '70px'"
            >
              <UserSelect
                ref="userSelect"
                show-tab
                :role-name="item.roleName"
                :default-user="getDefaultUser(item)"
                @user-choose="item.chooseUser"
              />
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'select'">
            <el-form-item
              ref="elFormItem"
              :label="item.label"
              :prop="item.prop ? item.prop : ''"
              :label-width="item.labelWidth || '70px'"
              class="search-form-item"
            >
              <el-select
                v-model="value[item.name]"
                collapse-tags
                :style="{ width: item.width ? item.width : '170px' }"
                :placeholder="item.placeholder"
                filterable
                clearable
                :multiple="item.multiple === true"
              >
                <el-option
                  v-for="(j, k) in item.optList"
                  :key="k"
                  :label="j.label"
                  :value="j.value"
                />
              </el-select>
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'daterange'">
            <el-form-item
              ref="elFormItem"
              :label="item.label"
              :prop="item.prop ? item.prop : ''"
              class="search-form-item"
              :label-width="item.labelWidth || '70px'"
            >
              <el-date-picker
                v-model="value[item.name]"
                align="right"
                class="mt-2"
                type="daterange"
                range-separator="~"
                :start-placeholder="item.startPlaceholder || '开始日期'"
                :end-placeholder="item.endPlaceholder || '结束日期'"
                :value-format="item.dateFormate || 'yyyy-MM-dd'"
                clearable
                :style="{ width: item.dateRangeWidth || '260px' }"
                :picker-options="pickerOptions"
                :disabled="item.disabled"
                @input="item.change"
              />
            </el-form-item>
          </template>
          <template v-else-if="item.type === 'cascader'">
            <el-form-item
              ref="elFormItem"
              :label="item.label"
              :prop="item.prop ? item.prop : ''"
              :label-width="item.labelWidth || '70px'"
              class="search-form-item"
            >
              <el-cascader
                v-model="value[item.name]"
                style="width: 100%"
                :options="item.optList"
                :props="item.props"
                :placeholder="item.placeholder"
                clearable
              />
            </el-form-item>
          </template>
        </div>
      </div>

      <div
        class="searchBtn sw-search-btn"
        :style="{
          'align-items': !moreSearch ? 'flex-start' : 'flex-end',
          'margin-bottom': moreSearch ? '18px' : '',
        }"
      >
        <div class="btngroups">
          <el-button
            v-for="(item, index) in searchFormConfig.operate"
            :key="index"
            size="small"
            :type="item.type"
            class="pub-h-36"
            @click.stop.prevent="item.handleClick"
          >
            {{ item.name }}
          </el-button>
          <el-button
            v-show="isShowSearch"
            type="text"
            class="tbold pub-h-36"
            @click="toggle"
          >
            {{ moreSearch ? '收起' : '展开' }}

            <i
              :class="moreSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i>
          </el-button>
        </div>
        <slot name="operate"></slot>
      </div>
    </el-form>
  </div>
</template>
<script>
  import UserSelect from '@/components/user-select'
  export default {
    name: 'ErpSearch',
    components: {
      UserSelect,
    },
    props: {
      searchFormConfig: {
        type: Object,
        default: () => {},
      },
      value: {
        type: Object,
        default: () => {},
      },
      // eslint-disable-next-line vue/require-default-prop
      rules: {
        type: Object,
      },
    },
    data() {
      return {
        isShowSearch: true,
        moreSearch: true,
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    mounted() {
      this.initMoreContent()
      window.onresize = () => {
        this.initMoreContent()
      }
    },
    methods: {
      // 搜索规则校验
      validateForm() {
        let flag = null
        let that = this
        that.$refs['searchForm'].validate((valid) => {
          valid ? (flag = true) : (flag = false)
        })
        return flag
      },

      // 重置
      resetFields() {
        this.$refs['searchForm'].resetFields()
      },

      // 重置userselect人员选择
      resetUserSelect() {
        let self = this
        let refs = self.$refs.userSelect
        if (!Array.isArray(refs)) {
          refs = [refs]
        }

        refs.forEach((ref) => {
          ref.user = ''
        })
      },

      toggle() {
        this[this.moreSearch ? 'moreContentHide' : 'moreContentShow']()
      },

      // 收起
      moreContentHide() {
        this.$Bus.$emit('form-trigger')
        this.$nextTick(() => {
          let searchContainer = this.$refs.searchContainer
          if (!searchContainer) return
          let allWidth = this.$refs.searchContainer.offsetWidth
          let item = this.$refs.elFormItem
          let initWidth = 0
          for (let i = 0; i < item.length; i++) {
            initWidth += item[i].$el.offsetWidth + 12
            if (initWidth > allWidth - 50) {
              item[i].$el.style.display = 'none'
            }
          }
          this.moreSearch = !this.moreSearch
        })
      },

      // 展开
      moreContentShow() {
        this.$Bus.$emit('form-trigger')
        this.$nextTick(() => {
          let item = this.$refs.elFormItem
          for (let i = 0; i < item.length; i++) {
            item[i].$el.style.display = 'block'
          }
          this.moreSearch = !this.moreSearch
        })
      },

      // 初始化或改变浏览器窗口判断是否需要展开收起
      initMoreContent() {
        this.$nextTick(() => {
          let container = this.$refs.searchContainer
          if (!container) return
          let allWidth = this.$refs.searchContainer.offsetWidth
          let item = this.$refs.elFormItem
          let initWidth = 0
          let flag = true
          for (let i = 0; i < item.length; i++) {
            item[i].$el.style.display = 'block'
            initWidth += item[i].$el.offsetWidth + 12
            if (item[i + 1]) {
              if (
                flag &&
                initWidth + item[i + 1].$el.offsetWidth + 12 > allWidth - 60
              ) {
                flag = false
              }
            }
          }
          if (initWidth > allWidth - 60) {
            this.isShowSearch = true
            this.moreSearch = true
          } else {
            this.isShowSearch = false
            this.moreSearch = false
          }
          if (this.moreSearch && !this.searchFormConfig.defaultExpand) {
            this.moreContentHide()
          }
        })
      },

      // 用户默认回显
      getDefaultUser(item) {
        const key = item.defaultUserKey,
          value = this.value || {}
        if (key && value[key + 'Name']) {
          return {
            userId: value[key + 'Id'],
            userName: value[key + 'Name'],
            userNameEn: value[key + 'NameEn'],
          }
        } else {
          return {}
        }
      },
      enterSearch(e) {
        e && e.target?.blur()
        this.$emit('enter-search')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search-form {
    .base-search_form {
      display: flex;
      justify-content: space-between;

      .search-container {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-flow: row wrap;
        padding-right: 20px;
        flex-grow: 1;

        > div {
          margin-right: 10px;
        }
      }

      .more-search {
        position: absolute;
        top: 0;
        width: 60px;
        line-height: 30px;
        font-size: 14px;
        cursor: pointer;
        color: #000;
        text-align: right;

        span {
          display: block;
          height: 40px;

          i {
            color: #687c97;
            margin-left: 5px;
            font-weight: 600;
            vertical-align: middle;
          }
        }
      }

      .searchBtn {
        display: flex;

        .btngroups {
          display: inline-flex;
          align-items: flex-start;
          margin-left: 5px;

          .el-button {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .mt-2 {
      margin-top: 2px;
    }

    .tbold {
      font-weight: 500;
      font-size: 13px;
      color: #309bff;
      margin-left: 15px;
    }
  }
</style>
<style>
  .el-select__tags-text {
    display: inline-block;
    max-width: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

@import "~@/erp/styles/variables/variables.scss";




























































































.erp-layout-vertical {
  .fixed-header {
    left: $base-left-menu-width;
    width: $base-right-content-width;
  }
}
.is-print {
  margin: -45px 0 0 0 !important;
}
